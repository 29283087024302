import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { DowwnloadService } from 'src/app/services/dowwnload.service';

@Component({
  selector: 'app-download-drf',
  templateUrl: './download-drf.component.html',
  styleUrls: ['./download-drf.component.scss']
})
export class DownloadDrfComponent implements OnInit {

 
  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;
  @Input() stateModalDownload: boolean;
  @Input() urlArcgis2: any;
  @Output() reponseStateModalDownload = new EventEmitter();


  titleModal: string = 'Descarga de Datos';
  isButtonDisabled: boolean = false;

  currentType: string;
  currentInstrument: string;

  channelsOriginal: any = [];
  instruments: any = [];
  types: any[] = [
    { id: 'piezometros', name: 'Piezómetros Cuerda Vibrante' },
    { id: 'clinoextensometros', name: 'Clinoextensómetros' },
    // { id: '3', name: 'Piezómetros Cuerda Vibrante' }
  ];

  files: any = [];
  foundfiles: any;
  nameDownload: string;

  constructor( private dowwnloadService: DowwnloadService ) { }

  ngOnInit(): void {
    
    this.getFiles();
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModalDownload) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES: "+this.stateModalDownload);

      this.addModal.show();
      this.reponseStateModalDownload.emit(false);
    }
  }

  download(){

    this.isButtonDisabled = true;
    //this.getDataDrf(this.currentInstrument);
    this.foundfiles = this.files.find(item => item.instrumento_id === this.currentInstrument);
    console.log('foundfiles: '+this.foundfiles.archivo);
    this.downloadFile(this.currentInstrument);
  }

  openModal(){

    this.addModal.show();
  }

  changedType(event){

    console.log('EVENT: '+event.id);
    //this.instruments = this.channelsOriginal.filter((object) => object.sensor_type_id == event.gid && object.id != 0);
    this.currentType = event.name;
    this.nameDownload = event.type2;
    this.getIdByType(event.id);
  }

  changedInstrument(event){

    console.log('EVENT: '+event.gid);
    this.currentInstrument = event.gid;
  }

  downloadFile(id: string): void {
    this.isButtonDisabled = true; // Disable button while downloading
  
    this.dowwnloadService.getFile(id).subscribe({
      next: (response: Blob) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
  
        // Set the download attribute with the desired filename if available
        const fileName = this.getFileName(response); // Custom function to get the filename from response headers
        link.download = this.foundfiles.archivo || 'archivo.csv'; // Default filename if not found
  
        link.click();
        window.URL.revokeObjectURL(url); // Clean up the URL
  
        this.isButtonDisabled = false; // Re-enable button after download
      },
      error: (error) => {
        console.error('Error downloading the file', error);
        this.isButtonDisabled = false; // Re-enable button in case of error
      }
    });
  }
  

  private getFileName(response: Blob): string | null {
    // Logic to extract filename from response headers (if applicable)
    // You may need to adjust this based on your server response
    const contentDisposition = response.type; // Modify to extract from headers if available
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
    return matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : null;
  }

  getIdByType(id: string){

    this.dowwnloadService.getIdByType(id).subscribe({
      next: (resp) => { 
        this.channelsOriginal = resp;
        this.instruments = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }

  getFiles(){

    this.dowwnloadService.getFiles().subscribe({
      next: (resp) => { 
        this.files = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }
}

