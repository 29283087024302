import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalAcceptComponent } from './modal-accept/modal-accept.component';

import { CUSTOM_ELEMENTS_SCHEMA,  NO_ERRORS_SCHEMA } from '@angular/core';


import {MDBSpinningPreloader, MDBBootstrapModulesPro, CharCounterModule} from 'ng-uikit-pro-standard';
import {AppComponent} from 'src/app/app.component';

import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { InputsModule, ModalModule, WavesModule, ButtonsModule, IconsModule, TableModule } from 'ng-uikit-pro-standard';
import { CardsComponent } from './cards/cards.component';
import { TableComponent } from './table/table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableDownloadComponent } from './table-download/table-download.component';
import { DownloadDataComponent } from './download-data/download-data.component';
import { DownloadDrfComponent } from './download-drf/download-drf.component';


@NgModule({
  declarations: [
    ModalAcceptComponent,
    CardsComponent,
    TableComponent,
    TableDownloadComponent,
    DownloadDataComponent,
    DownloadDrfComponent
  ],
  exports: [
    ModalAcceptComponent,
    CardsComponent,
    TableComponent,
    TableDownloadComponent,
    DownloadDataComponent,
    DownloadDrfComponent
  ],
  imports: [
    CommonModule,
    InputsModule, WavesModule, ButtonsModule, CharCounterModule,
    IconsModule,
    CharCounterModule,
    FormsModule,
    ReactiveFormsModule,
    WavesModule,
    TableModule,
    IconsModule,
    MDBBootstrapModulesPro.forRoot(),
    ModalModule.forRoot(),
  ],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule { }
