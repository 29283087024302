import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Gis } from '../models/gis';

import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

const base_url = environment.base_url;
const base_cmp = environment.base_cmp;
const base_drf = environment.base_drf;

  // Credenciales de usuario y contraseña DRF
  const username = 'dinesh';
  const password = 'dinesh';

  const headers = new HttpHeaders({
    'Authorization': 'Basic ' + btoa(`${username}:${password}`)
  });

@Injectable({
  providedIn: 'root'
})
export class DowwnloadService {

  constructor( private router: Router, private http: HttpClient ) { }

  // Crear el header con autorización básica


  //CMP
  getCrackmeter(){
    const url = `${ base_cmp }/data/registros/crackmeter`;
    return this.http.get( url );
  }

  getTypes(){
    const url = `${ base_cmp }/data/sensor_types/`;
    return this.http.get( url );
  }

  getChannels(){
    const url = `${ base_cmp }/data/channels/`;
    return this.http.get( url );
  }

  getData(id: string, type: string){
    const url = `${ base_cmp }/data/registros/${type}/${id}`;
    return this.http.get( url );
  }

  
  //DRF
  // getCrackmeter(){
  //   const url = `${ base_cmp }/data/registros/crackmeter`;
  //   return this.http.get( url );
  // }

  getIdByType(id: string){
    const url = `${ base_drf }/${id}`;
    return this.http.get(url, { headers });
  }

  getFileById(id: string){
    const url = `${ base_drf }/${id}`;
    return this.http.get(url, { headers });
  }

  getFiles(){
    const url = `${ base_drf }/descargas`;
    return this.http.get(url, { headers });
  }

  getDataDrf(id: string){
    const url = `${ base_drf }/descargas/${id}`;
    return this.http.get(url, { headers, responseType: 'blob' as 'json' }); // responseType 'blob' para archivos
  }

  // downloadFile(id: string): Observable<Blob> {
  //   const url = `${ base_drf }/descargas/${id}`; // URL dinámica
  //   return this.http.get(url, { headers: headers, responseType: 'blob' });
  // }

  // extractFileName(contentDisposition: string): string | null {
  //   const matches = /filename[^*=]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
  //   return matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : null;
  // }

  // downloadFile(url: string): void {
  //   this.http.get(url, { headers, observe: 'response', responseType: 'blob' }).pipe(
  //     map((response: HttpResponse<Blob>) => {
  //       const contentDisposition = response.headers.get('content-disposition');
  //       let filename = 'downloaded-file.csv'; // Nombre por defecto si no se puede determinar
  //       console.log('Response Headers:', response.headers.keys());

  //       console.log('Content-Disposition:', contentDisposition); // Registro para depurar
  
  //       if (contentDisposition) {
  //         // Usamos una expresión regular para extraer el nombre del archivo
  //         const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
  //         if (matches && matches[1]) {
  //           filename = matches[1].replace(/['"]/g, ''); // Elimina comillas si las hay
  //           console.log('Filename extracted from header:', filename); // Registro para depurar
  //         } else {
  //           console.warn('No valid filename found in Content-Disposition header'); // Advertencia si no se encuentra
  //         }
  //       } else {
  //         console.warn('Content-Disposition header is not present'); // Advertencia si no está presente
  //       }
  
  //       // Guarda el archivo usando el nombre extraído
  //       saveAs(new Blob([response.body]), filename);
  //     })
  //   ).subscribe(
  //     () => {
  //       console.log('Archivo descargado exitosamente');
  //     },
  //     error => {
  //       console.error('Error downloading the file', error);
  //     }
  //   );
  // }

  getFile(id: string): Observable<Blob> {

    return this.http.get<Blob>(`${base_drf}/descargas/${id}`, {
      headers: headers,
      responseType: 'blob' as 'json', // Specify response type
    });
  }
  
  
  
  
  

}