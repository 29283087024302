import { GisService } from './../../services/gis.service';
import { Component, OnInit } from '@angular/core';
import { Gis } from 'src/app/models/gis';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gis',
  templateUrl: './gis.component.html',
  styleUrls: ['./gis.component.scss']
})
export class GisComponent implements OnInit {

  constructor( private router: Router, private gisService: GisService) { }

  user;
  urlArcgis2;
  stateModalDownload: boolean = false;
  buttonState: boolean = false;

  stateModalDownloadDrf: boolean = false;
  buttonStateDrf: boolean = false;

  

  ngOnInit(): void {

    this.urlArcgis2 = JSON.parse(localStorage.getItem("gis"));
    console.log('URL: '+this.urlArcgis2.download);
    document.getElementById("idframe").setAttribute("src", this.urlArcgis2.url);
    this.user = JSON.parse(localStorage.getItem('user') || '');

    // console.log('URL GIS: '+ this.urlArcgis2.service);
    // this.buttonStateDrf = true;

    if(this.urlArcgis2.download && this.urlArcgis2.typedownload == 'drf') this.buttonStateDrf = true;
    if(this.urlArcgis2.download && this.urlArcgis2.typedownload == 'cmp' ) this.buttonState = true;
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 7000);
  }

  openModal(){

    console.log('openModal');
    console.log(this.stateModalDownload);
    this.stateModalDownload = true;
  }

  onResponseModalDownload(event){

    this.stateModalDownload = false;
  }


  openModalDrf(){

    console.log('openModalDrf');
    this.stateModalDownloadDrf = true;
  }

  onResponseModalDownloadDrf(event){

    this.stateModalDownloadDrf = false;
  }


  

}

