
  <main class="background-preview animated fadeIn slow">
    <div class="main-view">
      <div class="d-flex justify-content-between">
        <mdb-breadcrumb *ngIf="user.role == 'Admin' || user.role == 'Trabajador EMT'" customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item class="breadcrumbs-disabled" routerLink="/rep/companies"><i class="fa-solid fa-house mr-1"></i>Compañias</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/unitsEmt" class="breadcrumbs-disabled">Unidades</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/servicesUnit" class="active"class="breadcrumbs-disabled">Servicios</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/preview" class="breadcrumbs-disabled">Módulos</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="breadcrumbs-enabled">Visualización</mdb-breadcrumb-item>
        </mdb-breadcrumb>
    
        <mdb-breadcrumb *ngIf="user.role == 'Cliente'" customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item routerLink="/rep/units" class="breadcrumbs-disabled"><i class="fa-solid fa-house mr-1"></i>Unidades</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/servicesUnit" class="active"class="breadcrumbs-disabled">Servicios</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/preview" class="breadcrumbs-disabled">Módulos</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="breadcrumbs-enabled">Visualización</mdb-breadcrumb-item>
        </mdb-breadcrumb>
        <!-- <app-navbar-preview></app-navbar-preview> -->
    
        <button type="button" mdbBtn *ngIf="buttonState == true" class="bwaves-light button-accept-modals d-flex align-items-center" data-dismiss="modal"
        (click)="openModal()" mdbWavesEffect>Descargar</button> 

        <button type="button" mdbBtn *ngIf="buttonStateDrf == true" class="bwaves-light button-accept-modals d-flex align-items-center" data-dismiss="modal"
        (click)="openModalDrf()" mdbWavesEffect>Descargar</button> 
      </div>
      <main>
        <div class="container-iframe">
          <iframe id="idframe"  name="survey123webform" class="responsive-iframe"  frameborder="0" marginheight="0" marginwidth="0" title="Bug Map Zoom Level" allowfullscreen="0"
          allow="geolocation https://survey123.arcgis.com; camera https://survey123.arcgis.com">
          </iframe>
        </div>
      </main>
    </div>
</main>

<ngx-spinner bdColor = "rgba(12,32,34,0.97)" size = "large" color = "#d77a2a" type = "ball-clip-rotate-pulse" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>

<app-download-data [stateModalDownload]="stateModalDownload" [urlArcgis2]="urlArcgis2" (reponseStateModalDownload)="onResponseModalDownload($event)"></app-download-data>
<app-download-drf [stateModalDownload]="stateModalDownloadDrf" [urlArcgis2]="urlArcgis2" (reponseStateModalDownload)="onResponseModalDownloadDrf($event)"></app-download-drf>